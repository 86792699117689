import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer';

const createStore = () => {
  const store = configureStore({
    reducer: reducer,
  });

  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./reducer', () => {
      store.replaceReducer(reducer);
    });
  }

  return store;
};

export { createStore as default };
