import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'envoc-table/dist/css/envoc-table-styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/index.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from 'app';
import { config } from '@fortawesome/fontawesome-svg-core';
import { createStore } from 'shared/state';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

config.autoAddCss = false;
const store = createStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//This was turned off when other attempts to have the waiting service worker take over failed
//We should investiage again later, but that needs to be verified and working before we turn it back on.
//https://envoc.tpondemand.com/entity/114528-front-end-requires-hard-refresh-after
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
