function NavigationPathConverter(path) {
  const parts = path.split('/');
  const convertedParts = parts.map((part) =>
    part.startsWith(':')
      ? part // if we are resolving a parameter (indicated by the colon) then we should NOT convert to kebab case
      : part // convert to kebab case
          .replace(/([a-z])([A-Z])/g, '$1-$2')
          .replace(/\s+/g, '-')
          .toLowerCase()
  );

  return convertedParts.join('/');
}

module.exports = { NavigationPathConverter };
