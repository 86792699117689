import { useEffect, useRef, useState } from 'react';
import { EventsType, useIdleTimer } from 'react-idle-timer';
import { FormattedRelativeTime } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { addSeconds, differenceInSeconds } from 'date-fns';
import { AccountService } from 'api';
import { authActions, authSelectors } from 'shared/state';
import ButtonSolidRef from './ButtonSolid';
import Modal from './Modal';

const ACTIVE_EVENTS: EventsType[] = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
];

export default function KeepAlive() {
  const dispatch = useDispatch();
  const auth = useSelector(authSelectors.getAuth);

  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [logoutDate, setLogoutDate] = useState<Date | undefined>();

  const saved = localStorage.getItem('rememberMe');
  let rememberMe = false;
  if (saved !== null) {
    rememberMe = JSON.parse(saved);
  }

  const shouldKeepAlive = !!auth?.user?.id && !rememberMe;
  //Hard coded to 15 minutes since the actual cookie expiration is 1 year for the 'remember me' functionality
  const keepAliveIntervalMilliseconds =
    (15 * 60 * 1000) / 2;

  const responseTimeSeconds = 120;

  const appearIdleTimer = useIdleTimer({
    events: ACTIVE_EVENTS,
    timeout: keepAliveIntervalMilliseconds,
    startOnMount: false,
    stopOnIdle: true,
    startManually: true,
    crossTab: true,
    onActive: () => {
      if (!shouldKeepAlive) {
        return;
      }

      setLogoutDate(undefined);
      appearIdleTimer.reset();
      appearIdleTimer.start();
    },
    onIdle: () => {
      if (!shouldKeepAlive) {
        return;
      }

      const logoutDate = addSeconds(new Date(), responseTimeSeconds);
      setLogoutDate(logoutDate);
    },
  });

  useEffect(() => {
    if (shouldKeepAlive && !logoutDate) {
      appearIdleTimer.start();
    }
    if (!shouldKeepAlive || !!logoutDate) {
      appearIdleTimer.pause();
    }
  }, [appearIdleTimer, logoutDate, shouldKeepAlive]);

  useEffect(() => {
    if (!logoutDate || !shouldKeepAlive) {
      return;
    }
    const remaining = logoutDate.getTime() - new Date().getTime();

    const handle = setTimeout(() => {
      setIsLoggingOut(true);
      dispatch(authActions.logout());
      setIsLoggingOut(false);
      setLogoutDate(undefined);
    }, remaining);
    return () => clearTimeout(handle);
  }, [logoutDate, shouldKeepAlive, dispatch]);

  useEffect(() => {
    if (!!logoutDate || !shouldKeepAlive) {
      return;
    }

    const intervalHandle = setInterval(
      AccountService.getLoggedInUserDetails,
      keepAliveIntervalMilliseconds
    );

    return () => {
      clearInterval(intervalHandle);
    };
  }, [keepAliveIntervalMilliseconds, logoutDate, shouldKeepAlive, dispatch]);

  const stayLoggedInButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <Modal
      show={!!logoutDate && shouldKeepAlive}
      initialFocus={stayLoggedInButtonRef}
      onHide={() => setLogoutDate(undefined)}>
      <Modal.Header>
        <h1>You appear to be idle</h1>
      </Modal.Header>
      <Modal.Body>
        {!isLoggingOut ? (
          <>
            Please click 'Stay Logged In' to remain logged into this session.
            <br />
            You will automatically be logged out{' '}
            {logoutDate ? (
              <FormattedRelativeTime
                value={differenceInSeconds(logoutDate, new Date())}
                updateIntervalInSeconds={1}
              />
            ) : (
              'soon.'
            )}
          </>
        ) : (
          'You are being logged out...'
        )}
      </Modal.Body>
      {!isLoggingOut && (
        <Modal.Footer>
          <ButtonSolidRef
            ref={stayLoggedInButtonRef}
            color="indigo"
            onClick={() => {
              setLogoutDate(undefined);
              AccountService.getLoggedInUserDetails();
            }}>
            Stay Logged In
          </ButtonSolidRef>
        </Modal.Footer>
      )}
    </Modal>
  );
}
