import React from 'react';

export interface SingleActionPageProps {
  children: React.ReactNode;
}

export default function SingleActionPage({ children }: SingleActionPageProps) {
  return (
    <div className="flex content-center justify-center w-full overflow-y-scroll single-action-page min-h-screenk bg-gray-50">
      <div className="w-full px-10 py-8 mx-1 my-auto bg-white rounded-lg shadow sm:mx-auto sm:w-max">
        {children}
      </div>
    </div>
  );
}
