import { ForwardedRef, forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classnames from 'classnames';
import StrongLink, { StrongLinkProps } from './StrongLink';

interface StrongLinkBlueProps extends StrongLinkProps {}

interface WeakLinkBlueProps extends LinkProps {
  unsafe: true;
}

type LinkBlueProps = StrongLinkBlueProps | WeakLinkBlueProps;

/** link that looks like the standard blue links people expect */
const LinkBlueRef = forwardRef(LinkBlue) as (
  // We are using a type assertion here because we want the external API to reflect the generic 'LinkBlue' while still supporting refs
  // see: https://fettblog.eu/typescript-react-generic-forward-refs/#option-1%3A-type-assertion
  props: LinkBlueProps & React.RefAttributes<HTMLAnchorElement>
) => ReturnType<typeof LinkBlue>;

export default LinkBlueRef;

function LinkBlue(
  { className, children, ...props }: LinkBlueProps,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  let classNamesResult = classnames(
    className,
    'text-blue-500 ring-pbpurple-200 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 '
  );
  if ('unsafe' in props) {
    const { unsafe, ...rest } = props;
    return (
      <Link {...rest} ref={ref} className={classNamesResult}>
        {children}
      </Link>
    );
  } else {
    return (
      <StrongLink {...props} ref={ref} className={classNamesResult}>
        {children}
      </StrongLink>
    );
  }
}
