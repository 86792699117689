/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AccountService {
  /**
   *
   */
  static getLoggedInUserDetails(options: IRequestOptions = {}): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/me';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCookieExpiration(options: IRequestOptions = {}): Promise<DoubleApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/cookie-expiration';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalLogin(
    params: {
      /**  */
      provider?: string;
      /**  */
      returnUrl?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/ExternalLogin';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { provider: params['provider'], returnUrl: params['returnUrl'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalLoginCallback(
    params: {
      /**  */
      returnUrl?: string;
      /**  */
      remoteError?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/ExternalLoginCallback';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { returnUrl: params['returnUrl'], remoteError: params['remoteError'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static register(
    params: {
      /**  */
      returnUrl?: string;
      /** requestBody */
      body?: RegisterUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { returnUrl: params['returnUrl'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static login(
    params: {
      /** requestBody */
      body?: PasswordLoginCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static forgotPassword(
    params: {
      /** requestBody */
      body?: ForgotPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/forgotpassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetPassword(
    params: {
      /** requestBody */
      body?: ResetPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/resetpassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /** requestBody */
      body?: ChangePasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/changepassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeEmail(
    params: {
      /** requestBody */
      body?: ChangeEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/changeemail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeCommunicationPreference(
    params: {
      /** requestBody */
      body?: UpdateCommunicationPreferenceCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/communicationpreference';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static logout(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/logout';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confirmAccountRedirect(
    params: {
      /**  */
      email?: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/confirm-account-redirect';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { email: params['email'], token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confirmAccount(
    params: {
      /**  */
      email?: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountConfirmationResultApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/confirm-account';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { email: params['email'], token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BodyTraceService {
  /**
   *
   */
  static syncWeight(
    params: {
      /** requestBody */
      body?: SyncWeightCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/bodytrace';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CounselorsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CounselorDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/counselors/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCounselorById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CounselorDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/counselors/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createCounselor(
    params: {
      /** requestBody */
      body?: CreateCounselorCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CounselorDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/counselors';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateCounselor(
    params: {
      /** requestBody */
      body?: UpdateCounselorCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CounselorDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/counselors';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FileResourcesService {
  /**
   *
   */
  static getBySasToken(
    params: {
      /**  */
      fileResourceGuid: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/file-resources/{fileResourceGuid}';
      url = url.replace('{fileResourceGuid}', params['fileResourceGuid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class GroupsService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      siteId?: number;
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GroupDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/groups/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { siteId: params['siteId'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getGroupById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GroupDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/groups/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteGroupById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/groups/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createGroup(
    params: {
      /** requestBody */
      body?: CreateGroupCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/groups';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateGroup(
    params: {
      /** requestBody */
      body?: UpdateGroupCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/groups';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getGroupOptions(
    params: {
      /**  */
      siteId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/groups/groupOptions/{siteId}';
      url = url.replace('{siteId}', params['siteId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDefaultGroup(options: IRequestOptions = {}): Promise<DefaultGroupDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/groups/defaultGroup';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MessagesService {
  /**
   *
   */
  static getMessageThreads(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MessageThreadDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/messages/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMessageThread(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MessageGroupDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/messages/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createMessage(
    params: {
      /** requestBody */
      body?: CreateMessageCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/messages';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRecipientOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/messages/recipient-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUnreadCount(options: IRequestOptions = {}): Promise<Int32ApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/messages/unread-count';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MobileConfigurationService {
  /**
   *
   */
  static checkForForcedUpdate(
    params: {
      /**  */
      version: string;
      /**  */
      platform: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/mobile-configuration/{version}/{platform}';
      url = url.replace('{version}', params['version'] + '');
      url = url.replace('{platform}', params['platform'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ParticipantsService {
  /**
   *
   */
  static getParticipantsByStudyAndArm(
    params: {
      /**  */
      siteId?: number;
      /**  */
      groupId?: number;
      /**  */
      studyId?: number;
      /**  */
      studyArmId?: number;
      /**  */
      counselorId?: number;
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ParticipantDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/participants-by-study-and-arm';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = {
        siteId: params['siteId'],
        groupId: params['groupId'],
        studyId: params['studyId'],
        studyArmId: params['studyArmId'],
        counselorId: params['counselorId']
      };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getParticipantsByCounselor(
    params: {
      /**  */
      counselorId?: number;
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ParticipantListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/participants-by-counselor';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { counselorId: params['counselorId'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetLegacyParticipantsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LegacyParticipantDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLegacyParticipantAndWeightsById(
    params: {
      /**  */
      id: number;
      /**  */
      startDate?: string;
      /**  */
      endDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LegacyParticipantDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/legacy/data/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { StartDate: params['startDate'], EndDate: params['endDate'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getParticipantAndWeightsById(
    params: {
      /**  */
      id: number;
      /**  */
      startDate?: string;
      /**  */
      endDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ParticipantDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/data/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { StartDate: params['startDate'], EndDate: params['endDate'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getParticipantById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ParticipantDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteParticipantById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createParticipant(
    params: {
      /** requestBody */
      body?: CreateParticipantCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateParticipant(
    params: {
      /** requestBody */
      body?: UpdateParticipantCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createLegacyParticipant(
    params: {
      /** requestBody */
      body?: CreateLegacyParticipantCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/legacy';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateLegacyParticipant(
    params: {
      /** requestBody */
      body?: UpdateLegacyParticipantCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/legacy';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCounselorOptions(
    params: {
      /**  */
      counselorId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/counselorOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { counselorId: params['counselorId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCounselorOptionsByGroup(
    params: {
      /**  */
      groupId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/counselorOptionsByGroup';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { groupId: params['groupId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLegacyParticipantOptions(
    params: {
      /**  */
      participantId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/legacyParticipantOptions/{participantId}';
      url = url.replace('{participantId}', params['participantId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getParticipantOptions(
    params: {
      /**  */
      participantId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/participantOptions/{participantId}';
      url = url.replace('{participantId}', params['participantId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getScaleOptions(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/scaleOptions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPercentOptions(options: IRequestOptions = {}): Promise<StringOptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/participants/percentOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ScalesService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ScaleDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/scales/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getScaleById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ScaleDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/scales/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteScaleById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/scales/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createScale(
    params: {
      /** requestBody */
      body?: CreateScaleCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/scales';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateScale(
    params: {
      /** requestBody */
      body?: UpdateScaleCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/scales';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static importScales(
    params: {
      /**  */
      imageFile: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/scales/import';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['imageFile']) {
        if (Object.prototype.toString.call(params['imageFile']) === '[object Array]') {
          for (const item of params['imageFile']) {
            data.append('ImageFile', item as any);
          }
        } else {
          data.append('ImageFile', params['imageFile'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SessionMaterialsService {
  /**
   *
   */
  static getMaterials(
    params: {
      /**  */
      sessionId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SessionMaterialDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/session-materials';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { sessionId: params['sessionId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addMaterials(
    params: {
      /**  */
      sessionId: number;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/session-materials';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['sessionId']) {
        if (Object.prototype.toString.call(params['sessionId']) === '[object Array]') {
          for (const item of params['sessionId']) {
            data.append('SessionId', item as any);
          }
        } else {
          data.append('SessionId', params['sessionId'] as any);
        }
      }

      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('File', item as any);
          }
        } else {
          data.append('File', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteMaterials(
    params: {
      /** requestBody */
      body?: DeleteSessionMaterialCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/session-materials/delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateFileName(
    params: {
      /** requestBody */
      body?: UpdateMaterialFileNameCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/session-materials/file-name';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMaterialOptionsBySession(
    params: {
      /**  */
      sessionId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/session-materials/material-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { sessionId: params['sessionId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SessionsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetSessionsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SessionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sessions/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSessionById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SessionListItemDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sessions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteSession(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sessions/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createSession(
    params: {
      /** requestBody */
      body?: CreateSessionCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sessions';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateSession(
    params: {
      /** requestBody */
      body?: UpdateSessionCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sessions';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateSessionDetails(
    params: {
      /** requestBody */
      body?: UpdateSessionDetailsCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sessions/details';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SessionSchedulesService {
  /**
   *
   */
  static getSessionScheduleByParticipantId(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SessionScheduleDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/session-schedules/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createOrUpdateSessionSchedule(
    params: {
      /** requestBody */
      body?: CreateOrUpdateSessionScheduleCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/session-schedules';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SettingsService {
  /**
   *
   */
  static getSettingByKey(
    params: {
      /**  */
      key: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{key}';
      url = url.replace('{key}', params['key'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: SettingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: SettingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SitesService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SiteDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sites/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSiteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SiteDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sites/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteSiteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sites/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createSite(
    params: {
      /** requestBody */
      body?: CreateSiteCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sites';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateSite(
    params: {
      /** requestBody */
      body?: UpdateSiteCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sites';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getManagerOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sites/managerOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSiteOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sites/siteOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDefaultSite(options: IRequestOptions = {}): Promise<Int32ApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sites/defaultSite';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StatusService {
  /**
   *
   */
  static getStatusAsync(options: IRequestOptions = {}): Promise<StatusResultListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/status';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StudiesService {
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      siteId?: number;
      /**  */
      groupId?: number;
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudyListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/studies/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { siteId: params['siteId'], groupId: params['groupId'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStudyById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudyDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/studies/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteStudyById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/studies/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createStudy(
    params: {
      /** requestBody */
      body?: CreateStudyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/studies';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateStudy(
    params: {
      /** requestBody */
      body?: UpdateStudyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/studies';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStudyOptions(
    params: {
      /**  */
      groupId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/studies/studyOptions/{groupId}';
      url = url.replace('{groupId}', params['groupId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDefaultStudy(options: IRequestOptions = {}): Promise<DefaultStudyDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/studies/defaultStudy';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StudyArmsService {
  /**
   *
   */
  static getStudyArmsByStudyId(
    params: {
      /**  */
      siteId?: number;
      /**  */
      groupId?: number;
      /**  */
      studyId?: number;
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudyArmListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/study-arms/study-arms-by-study-id';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { siteId: params['siteId'], groupId: params['groupId'], studyId: params['studyId'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudyArmListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/study-arms/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStudyArmById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StudyArmDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/study-arms/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteStudyArmById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/study-arms/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createStudyArm(
    params: {
      /** requestBody */
      body?: CreateStudyArmCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/study-arms';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateStudyArm(
    params: {
      /** requestBody */
      body?: UpdateStudyArmCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/study-arms';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPercentOptions(options: IRequestOptions = {}): Promise<StringOptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/study-arms/percentOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStudyArmOptions(
    params: {
      /**  */
      studyId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/study-arms/studyArmOptions/{studyId}';
      url = url.replace('{studyId}', params['studyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCounselorOptions(
    params: {
      /**  */
      studyArmId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/study-arms/counselorOptions/{studyArmId}';
      url = url.replace('{studyArmId}', params['studyArmId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDefaultStudyArm(options: IRequestOptions = {}): Promise<DefaultStudyArmDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/study-arms/defaultStudyArm';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UsersService {
  /**
   *
   */
  static getUsers(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUser(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postUserNoPhoto(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postUser(
    params: {
      /** requestBody */
      body?: CreateUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static lockUser(
    params: {
      /** requestBody */
      body?: LockUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/lock';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unlockUser(
    params: {
      /** requestBody */
      body?: UnlockUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/unlock';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRoleOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/roleOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendConfirmEmail(
    params: {
      /** requestBody */
      body?: SendConfirmAccountEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/sendConfirmEmail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class WeightsService {
  /**
   *
   */
  static getTable(
    params: {
      /** requestBody */
      body?: GetWeightsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WeightDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/weights/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getWeightById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WeightDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/weights/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteWeightById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/weights/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createWeight(
    params: {
      /** requestBody */
      body?: CreateWeightCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/weights';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateWeight(
    params: {
      /** requestBody */
      body?: UpdateWeightCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/weights';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static syncWeights(
    params: {
      /** requestBody */
      body?: SyncWeightsCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/weights/sync';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface AccountConfirmationResult {
  /**  */
  isPasswordResetRequired?: boolean;

  /**  */
  code?: string;
}

export interface AccountConfirmationResultApiResult {
  /**  */
  result?: AccountConfirmationResult;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface AnnotationPointDto {
  /**  */
  x?: number;

  /**  */
  y?: number;

  /**  */
  marker?: Marker;
}

export interface AuthenticationProperties {
  /**  */
  items?: object;

  /**  */
  parameters?: object;

  /**  */
  isPersistent?: boolean;

  /**  */
  redirectUri?: string;

  /**  */
  issuedUtc?: string;

  /**  */
  expiresUtc?: string;

  /**  */
  allowRefresh?: boolean;
}

export interface BooleanApiResult {
  /**  */
  result?: boolean;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ChallengeResult {
  /**  */
  authenticationSchemes?: string[];

  /**  */
  properties?: AuthenticationProperties;
}

export interface ChangeEmailCommand {
  /**  */
  oldEmail?: string;

  /**  */
  newEmail?: string;

  /**  */
  currentPassword?: string;
}

export interface ChangePasswordCommand {
  /**  */
  password?: string;

  /**  */
  newPassword?: string;

  /**  */
  confirmNewPassword?: string;
}

export interface CounselorDto {
  /**  */
  id?: number;

  /**  */
  siteId?: number;

  /**  */
  groupId?: number;

  /**  */
  email?: string;

  /**  */
  name?: string;

  /**  */
  siteName?: string;

  /**  */
  groupName?: string;

  /**  */
  hasLegacyAccess?: boolean;
}

export interface CounselorDtoApiResult {
  /**  */
  result?: CounselorDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface CounselorDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: CounselorDto[];
}

export interface CounselorDtoDataTablePageApiResult {
  /**  */
  result?: CounselorDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface CreateCounselorCommand {
  /**  */
  email?: string;

  /**  */
  name?: string;

  /**  */
  groupId?: number;

  /**  */
  hasLegacyAccess?: boolean;
}

export interface CreateGroupCommand {
  /**  */
  name?: string;

  /**  */
  siteId?: number;
}

export interface CreateLegacyParticipantCommand {
  /**  */
  penningtonId?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  status?: string;

  /**  */
  studyStartDate?: Date;

  /**  */
  studyEndDate?: Date;

  /**  */
  studyIntervalDays?: number;

  /**  */
  age?: number;

  /**  */
  gender?: string;

  /**  */
  height?: number;

  /**  */
  baselineWeight?: number;

  /**  */
  upperBoundBuffer?: number;

  /**  */
  lowerBoundBuffer?: number;

  /**  */
  boundModificationAmount?: number;

  /**  */
  boundModificationWeekInterval?: number;

  /**  */
  percentType?: PercentType;

  /**  */
  percentParameter?: number;

  /**  */
  counselorId?: number;

  /**  */
  scaleId?: number;
}

export interface CreateMessageCommand {
  /**  */
  body?: string;

  /**  */
  recipientId?: number;
}

export interface CreateOrUpdateSessionScheduleCommand {
  /**  */
  startDate?: string;

  /**  */
  frequency?: string;

  /**  */
  participantId?: number;
}

export interface CreateParticipantCommand {
  /**  */
  penningtonId?: string;

  /**  */
  nickname?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  communicationPreference?: number;

  /**  */
  status?: string;

  /**  */
  studyStartDate?: Date;

  /**  */
  studyEndDate?: Date;

  /**  */
  age?: number;

  /**  */
  gender?: string;

  /**  */
  height?: number;

  /**  */
  baselineWeight?: number;

  /**  */
  counselorId?: number;

  /**  */
  scaleId?: number;

  /**  */
  siteId?: number;

  /**  */
  groupId?: number;

  /**  */
  studyId?: number;

  /**  */
  studyArmId?: number;
}

export interface CreateScaleCommand {
  /**  */
  name?: string;

  /**  */
  scaleImei?: string;
}

export interface CreateSessionCommand {
  /**  */
  scheduledDate?: string;

  /**  */
  participantId?: number;
}

export interface CreateSiteCommand {
  /**  */
  name?: string;

  /**  */
  managerId?: number;
}

export interface CreateStudyArmCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  weightLossApproach?: PercentType;

  /**  */
  percentParameter?: number;

  /**  */
  upperBoundBuffer?: number;

  /**  */
  maxUpperBound?: number;

  /**  */
  lowerBoundBuffer?: number;

  /**  */
  maxLowerBound?: number;

  /**  */
  boundModificationAmount?: number;

  /**  */
  boundModificationWeekInterval?: number;

  /**  */
  weightLossPeriod?: number;

  /**  */
  weightMaintenancePeriod?: number;

  /**  */
  studyId?: number;
}

export interface CreateStudyCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  status?: number;

  /**  */
  numberOfParticipants?: number;

  /**  */
  groupId?: number;

  /**  */
  studyPopulations?: string[];
}

export interface CreateUserCommand {
  /**  */
  email?: string;

  /**  */
  name?: string;

  /**  */
  password?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  status?: number;

  /**  */
  userRole?: number;

  /**  */
  hasLegacyAccess?: boolean;
}

export interface CreateWeightCommand {
  /**  */
  kilograms?: number;

  /**  */
  pounds?: number;

  /**  */
  createdDate?: string;

  /**  */
  participantId?: number;

  /**  */
  useLbs?: boolean;

  /**  */
  isExcluded?: boolean;
}

export interface CreatedEntity {
  /**  */
  type?: string;

  /**  */
  id?: number;
}

export interface DataPointDto {
  /**  */
  x?: Date;

  /**  */
  y?: number;
}

export interface DataTableColumn {
  /**  */
  propertyName?: string;

  /**  */
  sortDirection?: string;

  /**  */
  searchTerm?: string;
}

export interface DataTableRequest {
  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface DefaultGroupDto {
  /**  */
  siteId?: number;

  /**  */
  groupId?: number;
}

export interface DefaultGroupDtoApiResult {
  /**  */
  result?: DefaultGroupDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface DefaultStudyArmDto {
  /**  */
  studyArmId?: number;

  /**  */
  studyId?: number;

  /**  */
  siteId?: number;

  /**  */
  groupId?: number;
}

export interface DefaultStudyArmDtoApiResult {
  /**  */
  result?: DefaultStudyArmDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface DefaultStudyDto {
  /**  */
  studyId?: number;

  /**  */
  siteId?: number;

  /**  */
  groupId?: number;
}

export interface DefaultStudyDtoApiResult {
  /**  */
  result?: DefaultStudyDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface DeleteSessionMaterialCommand {
  /**  */
  fileIds?: number[];
}

export interface DoubleApiResult {
  /**  */
  result?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface FileResourceUriResult {
  /**  */
  uri?: string;

  /**  */
  cacheUntil?: string;

  /**  */
  guid?: string;

  /**  */
  fileName?: string;

  /**  */
  mimeType?: string;
}

export interface ForgotPasswordCommand {
  /**  */
  email?: string;
}

export interface GetLegacyParticipantsRequest {
  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetSessionsRequest {
  /**  */
  participantId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GetWeightsRequest {
  /**  */
  participantId?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface GraphDto {
  /**  */
  series?: SeriesDto[];

  /**  */
  annotationPoints?: AnnotationPointDto[];

  /**  */
  trafficLightColor?: string;
}

export interface GroupDto {
  /**  */
  id?: number;

  /**  */
  siteId?: number;

  /**  */
  name?: string;

  /**  */
  siteName?: string;
}

export interface GroupDtoApiResult {
  /**  */
  result?: GroupDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface GroupDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: GroupDto[];
}

export interface GroupDtoDataTablePageApiResult {
  /**  */
  result?: GroupDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface Int32ApiResult {
  /**  */
  result?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LegacyParticipantDto {
  /**  */
  id?: number;

  /**  */
  penningtonId?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  status?: string;

  /**  */
  studyStartDate?: Date;

  /**  */
  studyEndDate?: Date;

  /**  */
  studyIntervalDays?: number;

  /**  */
  age?: number;

  /**  */
  gender?: string;

  /**  */
  height?: number;

  /**  */
  baselineWeight?: number;

  /**  */
  goalWeight?: number;

  /**  */
  baselineEnergyIntake?: number;

  /**  */
  newEnergyIntake?: number;

  /**  */
  upperBoundBuffer?: number;

  /**  */
  lowerBoundBuffer?: number;

  /**  */
  boundModificationAmount?: number;

  /**  */
  boundModificationWeekInterval?: number;

  /**  */
  percentType?: PercentType;

  /**  */
  percentParameter?: number;

  /**  */
  counselorEmail?: string;

  /**  */
  scaleName?: string;

  /**  */
  trafficLightIndicator?: string;

  /**  */
  latestWeight?: number;

  /**  */
  latestWeighIn?: string;

  /**  */
  counselorId?: number;

  /**  */
  scaleId?: number;

  /**  */
  graphData?: GraphDto;
}

export interface LegacyParticipantDtoApiResult {
  /**  */
  result?: LegacyParticipantDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LegacyParticipantDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: LegacyParticipantDto[];
}

export interface LegacyParticipantDtoDataTablePageApiResult {
  /**  */
  result?: LegacyParticipantDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LockUserCommand {
  /**  */
  email?: string;
}

export interface Marker {
  /**  */
  fillColor?: string;

  /**  */
  strokeColor?: string;

  /**  */
  size?: number;

  /**  */
  shape?: string;
}

export interface MessageDto {
  /**  */
  body?: string;

  /**  */
  sentByParticipant?: boolean;

  /**  */
  timestamp?: string;
}

export interface MessageGroupDto {
  /**  */
  messages?: MessageDto[];

  /**  */
  contactName?: string;

  /**  */
  contactEmail?: string;

  /**  */
  contactPhone?: string;

  /**  */
  contactId?: string;
}

export interface MessageGroupDtoApiResult {
  /**  */
  result?: MessageGroupDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface MessageThreadDto {
  /**  */
  contactName?: string;

  /**  */
  latestMessageBody?: string;

  /**  */
  unreadCount?: number;

  /**  */
  sentByParticipant?: boolean;

  /**  */
  participantId?: number;

  /**  */
  nonParticipantId?: number;

  /**  */
  lastMessageDate?: string;
}

export interface MessageThreadDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: MessageThreadDto[];
}

export interface MessageThreadDtoDataTablePageApiResult {
  /**  */
  result?: MessageThreadDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface OptionItemDto {
  /**  */
  value?: number;

  /**  */
  label?: string;

  /**  */
  metadata?: any | null;
}

export interface OptionItemDtoListApiResult {
  /**  */
  result?: OptionItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ParticipantDto {
  /**  */
  id?: number;

  /**  */
  penningtonId?: string;

  /**  */
  nickname?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  communicationPreference?: number;

  /**  */
  status?: string;

  /**  */
  studyStartDate?: Date;

  /**  */
  studyEndDate?: Date;

  /**  */
  studyIntervalDays?: number;

  /**  */
  age?: number;

  /**  */
  gender?: string;

  /**  */
  height?: number;

  /**  */
  baselineWeight?: number;

  /**  */
  goalWeight?: number;

  /**  */
  baselineEnergyIntake?: number;

  /**  */
  newEnergyIntake?: number;

  /**  */
  counselorEmail?: string;

  /**  */
  scaleName?: string;

  /**  */
  trafficLightIndicator?: string;

  /**  */
  latestWeight?: number;

  /**  */
  latestWeighIn?: string;

  /**  */
  counselorId?: number;

  /**  */
  scaleId?: number;

  /**  */
  siteId?: number;

  /**  */
  groupId?: number;

  /**  */
  studyId?: number;

  /**  */
  studyArmId?: number;

  /**  */
  graphData?: GraphDto;
}

export interface ParticipantDtoApiResult {
  /**  */
  result?: ParticipantDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ParticipantDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ParticipantDto[];
}

export interface ParticipantDtoDataTablePageApiResult {
  /**  */
  result?: ParticipantDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ParticipantListItemDto {
  /**  */
  id?: number;

  /**  */
  penningtonId?: string;

  /**  */
  nickname?: string;

  /**  */
  counselorEmail?: string;

  /**  */
  status?: string;

  /**  */
  studyStartDate?: Date;

  /**  */
  studyEndDate?: Date;

  /**  */
  trafficLightIndicator?: string;

  /**  */
  latestWeight?: number;

  /**  */
  latestWeighIn?: string;

  /**  */
  siteName?: string;

  /**  */
  groupName?: string;

  /**  */
  counselorId?: number;

  /**  */
  siteId?: number;

  /**  */
  groupId?: number;

  /**  */
  studyId?: number;

  /**  */
  studyArmId?: number;
}

export interface ParticipantListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ParticipantListItemDto[];
}

export interface ParticipantListItemDtoDataTablePageApiResult {
  /**  */
  result?: ParticipantListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface PasswordLoginCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  rememberMe?: boolean;
}

export interface RegisterUserCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;
}

export interface ResetPasswordCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  code?: string;
}

export interface ScaleDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  scaleImei?: string;

  /**  */
  participant?: string;
}

export interface ScaleDtoApiResult {
  /**  */
  result?: ScaleDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ScaleDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ScaleDto[];
}

export interface ScaleDtoDataTablePageApiResult {
  /**  */
  result?: ScaleDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SendConfirmAccountEmailCommand {
  /**  */
  email?: string;
}

export interface SeriesDto {
  /**  */
  name?: string;

  /**  */
  type?: string;

  /**  */
  color?: string;

  /**  */
  data?: DataPointDto[];
}

export interface SessionListItemDto {
  /**  */
  id?: number;

  /**  */
  scheduledDate?: string;

  /**  */
  interventionContactType?: string;

  /**  */
  participantPenningtonId?: string;

  /**  */
  toolboxNeeded?: boolean;

  /**  */
  preparedMaterials?: boolean;

  /**  */
  sentMaterials?: boolean;

  /**  */
  sentToolbox?: boolean;

  /**  */
  reviewedWeight?: boolean;

  /**  */
  reviewedPreviousGoals?: boolean;

  /**  */
  reviewedMaterials?: boolean;

  /**  */
  setNewGoals?: boolean;

  /**  */
  sentGoals?: boolean;

  /**  */
  counselorId?: number;

  /**  */
  counselorName?: string;

  /**  */
  contactMethod?: string;

  /**  */
  participantAttended?: boolean;

  /**  */
  reasonForMissing?: string;

  /**  */
  makeupSession?: boolean;

  /**  */
  sessionMaterialIds?: number[];

  /**  */
  sessionMaterialNames?: string;

  /**  */
  caloriePrescription?: number;

  /**  */
  stepsSinceLastSession?: number;

  /**  */
  activityMinutes?: number;

  /**  */
  additionalSessionInfo?: string;
}

export interface SessionListItemDtoApiResult {
  /**  */
  result?: SessionListItemDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SessionListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: SessionListItemDto[];
}

export interface SessionListItemDtoDataTablePageApiResult {
  /**  */
  result?: SessionListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SessionMaterialDto {
  /**  */
  id?: number;

  /**  */
  fileResource?: FileResourceUriResult;
}

export interface SessionMaterialDtoListApiResult {
  /**  */
  result?: SessionMaterialDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SessionScheduleDto {
  /**  */
  id?: number;

  /**  */
  startDate?: string;

  /**  */
  frequency?: string;

  /**  */
  participantId?: number;
}

export interface SessionScheduleDtoApiResult {
  /**  */
  result?: SessionScheduleDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SettingDto {
  /**  */
  id?: number;

  /**  */
  key?: string;

  /**  */
  value?: string;

  /**  */
  isEnabled?: boolean;
}

export interface SettingDtoApiResult {
  /**  */
  result?: SettingDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SettingDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: SettingDto[];
}

export interface SettingDtoDataTablePageApiResult {
  /**  */
  result?: SettingDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SiteDto {
  /**  */
  id?: number;

  /**  */
  managerId?: number;

  /**  */
  name?: string;

  /**  */
  managerEmail?: string;

  /**  */
  managerName?: string;
}

export interface SiteDtoApiResult {
  /**  */
  result?: SiteDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SiteDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: SiteDto[];
}

export interface SiteDtoDataTablePageApiResult {
  /**  */
  result?: SiteDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface StatusResult {
  /**  */
  name?: string;

  /**  */
  status?: string;
}

export interface StatusResultListApiResult {
  /**  */
  result?: StatusResult[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface StringApiResult {
  /**  */
  result?: string;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface StringOptionItemDto {
  /**  */
  value?: string;

  /**  */
  label?: string;

  /**  */
  metadata?: any | null;
}

export interface StringOptionItemDtoListApiResult {
  /**  */
  result?: StringOptionItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface StudyArmDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  weightLossApproach?: PercentType;

  /**  */
  percentParameter?: number;

  /**  */
  upperBoundBuffer?: number;

  /**  */
  maxUpperBound?: number;

  /**  */
  lowerBoundBuffer?: number;

  /**  */
  maxLowerBound?: number;

  /**  */
  boundModificationAmount?: number;

  /**  */
  boundModificationWeekInterval?: number;

  /**  */
  weightLossPeriod?: number;

  /**  */
  weightMaintenancePeriod?: number;

  /**  */
  studyId?: number;
}

export interface StudyArmDtoApiResult {
  /**  */
  result?: StudyArmDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface StudyArmListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  weightLossApproach?: string;

  /**  */
  percentParameter?: string;

  /**  */
  weightLossPeriod?: string;
}

export interface StudyArmListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: StudyArmListItemDto[];
}

export interface StudyArmListItemDtoDataTablePageApiResult {
  /**  */
  result?: StudyArmListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface StudyDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  status?: number;

  /**  */
  numberOfParticipants?: number;

  /**  */
  groupId?: number;

  /**  */
  studyPopulations?: string[];
}

export interface StudyDtoApiResult {
  /**  */
  result?: StudyDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface StudyListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  status?: string;

  /**  */
  numberOfParticipants?: number;

  /**  */
  studyPopulations?: string;
}

export interface StudyListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: StudyListItemDto[];
}

export interface StudyListItemDtoDataTablePageApiResult {
  /**  */
  result?: StudyListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SyncWeightCommand {
  /**  */
  imei?: string;

  /**  */
  ts?: number;

  /**  */
  values?: Values;
}

export interface SyncWeightsCommand {
  /**  */
  participantId?: number;
}

export interface UnlockUserCommand {
  /**  */
  email?: string;
}

export interface UpdateCommunicationPreferenceCommand {
  /**  */
  phoneNumber?: string;

  /**  */
  communicationPreference?: CommunicationPreference;
}

export interface UpdateCounselorCommand {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  name?: string;

  /**  */
  groupId?: number;

  /**  */
  hasLegacyAccess?: boolean;
}

export interface UpdateGroupCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  siteId?: number;
}

export interface UpdateLegacyParticipantCommand {
  /**  */
  id?: number;

  /**  */
  penningtonId?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  status?: string;

  /**  */
  studyStartDate?: Date;

  /**  */
  studyEndDate?: Date;

  /**  */
  studyIntervalDays?: number;

  /**  */
  age?: number;

  /**  */
  gender?: string;

  /**  */
  height?: number;

  /**  */
  baselineWeight?: number;

  /**  */
  upperBoundBuffer?: number;

  /**  */
  lowerBoundBuffer?: number;

  /**  */
  boundModificationAmount?: number;

  /**  */
  boundModificationWeekInterval?: number;

  /**  */
  percentType?: PercentType;

  /**  */
  percentParameter?: number;

  /**  */
  counselorId?: number;

  /**  */
  scaleId?: number;
}

export interface UpdateMaterialFileNameCommand {
  /**  */
  id?: number;

  /**  */
  fileName?: string;
}

export interface UpdateParticipantCommand {
  /**  */
  id?: number;

  /**  */
  penningtonId?: string;

  /**  */
  nickname?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  communicationPreference?: number;

  /**  */
  status?: string;

  /**  */
  studyStartDate?: Date;

  /**  */
  studyEndDate?: Date;

  /**  */
  age?: number;

  /**  */
  gender?: string;

  /**  */
  height?: number;

  /**  */
  baselineWeight?: number;

  /**  */
  counselorId?: number;

  /**  */
  scaleId?: number;

  /**  */
  siteId?: number;

  /**  */
  groupId?: number;

  /**  */
  studyId?: number;

  /**  */
  studyArmId?: number;
}

export interface UpdateScaleCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  scaleImei?: string;
}

export interface UpdateSessionCommand {
  /**  */
  scheduledDate?: string;

  /**  */
  sessionId?: number;
}

export interface UpdateSessionDetailsCommand {
  /**  */
  id?: number;

  /**  */
  scheduledDate?: string;

  /**  */
  interventionContactType?: string;

  /**  */
  participantPenningtonId?: string;

  /**  */
  toolboxNeeded?: boolean;

  /**  */
  preparedMaterials?: boolean;

  /**  */
  sentMaterials?: boolean;

  /**  */
  sentToolbox?: boolean;

  /**  */
  reviewedWeight?: boolean;

  /**  */
  reviewedPreviousGoals?: boolean;

  /**  */
  reviewedMaterials?: boolean;

  /**  */
  setNewGoals?: boolean;

  /**  */
  sentGoals?: boolean;

  /**  */
  counselorId?: number;

  /**  */
  counselorName?: string;

  /**  */
  contactMethod?: string;

  /**  */
  participantAttended?: boolean;

  /**  */
  reasonForMissing?: string;

  /**  */
  makeupSession?: boolean;

  /**  */
  sessionMaterialIds?: number[];

  /**  */
  sessionMaterialNames?: string;

  /**  */
  caloriePrescription?: number;

  /**  */
  stepsSinceLastSession?: number;

  /**  */
  activityMinutes?: number;

  /**  */
  additionalSessionInfo?: string;
}

export interface UpdateSiteCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  managerId?: number;
}

export interface UpdateStudyArmCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  weightLossApproach?: PercentType;

  /**  */
  percentParameter?: number;

  /**  */
  upperBoundBuffer?: number;

  /**  */
  maxUpperBound?: number;

  /**  */
  lowerBoundBuffer?: number;

  /**  */
  maxLowerBound?: number;

  /**  */
  boundModificationAmount?: number;

  /**  */
  boundModificationWeekInterval?: number;

  /**  */
  weightLossPeriod?: number;

  /**  */
  weightMaintenancePeriod?: number;

  /**  */
  studyId?: number;
}

export interface UpdateStudyCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  status?: number;

  /**  */
  numberOfParticipants?: number;

  /**  */
  groupId?: number;

  /**  */
  studyPopulations?: string[];
}

export interface UpdateUserCommand {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  name?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  username?: string;

  /**  */
  userRole?: number;

  /**  */
  status?: number;

  /**  */
  newPassword?: string;

  /**  */
  confirmNewPassword?: string;

  /**  */
  hasLegacyAccess?: boolean;
}

export interface UpdateWeightCommand {
  /**  */
  id?: number;

  /**  */
  kilograms?: number;

  /**  */
  pounds?: number;

  /**  */
  createdDate?: string;

  /**  */
  participantId?: number;

  /**  */
  useLbs?: boolean;

  /**  */
  isExcluded?: boolean;
}

export interface UserDto {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  name?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  status?: number;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  userRole?: number;

  /**  */
  userRoleName?: string;

  /**  */
  groupId?: number;

  /**  */
  hasLegacyAccess?: boolean;
}

export interface UserDtoApiResult {
  /**  */
  result?: UserDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface UserListItemDto {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  name?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  userRoleName?: string;

  /**  */
  status?: string;

  /**  */
  isLockedOut?: boolean;
}

export interface UserListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: UserListItemDto[];
}

export interface UserListItemDtoDataTablePageApiResult {
  /**  */
  result?: UserListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ValidationError {
  /**  */
  propertyName?: string;

  /**  */
  errorMessage?: string;
}

export interface Values {
  /**  */
  weight?: number;
}

export interface WeightDto {
  /**  */
  id?: number;

  /**  */
  kilograms?: number;

  /**  */
  pounds?: number;

  /**  */
  participant?: string;

  /**  */
  scale?: string;

  /**  */
  createdDate?: string;

  /**  */
  manuallyModified?: boolean;

  /**  */
  isExcluded?: boolean;

  /**  */
  participantId?: number;

  /**  */
  useLbs?: boolean;
}

export interface WeightDtoApiResult {
  /**  */
  result?: WeightDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface WeightDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: WeightDto[];
}

export interface WeightDtoDataTablePageApiResult {
  /**  */
  result?: WeightDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export enum CommunicationPreference {
  'Email' = 'Email',
  'Text' = 'Text',
  'None' = 'None'
}

export enum PercentType {
  'PercentWeightLoss' = 'PercentWeightLoss',
  'PercentEnergyRestriction' = 'PercentEnergyRestriction'
}
