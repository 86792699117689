import { useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { authSelectors } from 'shared/state';
import {
  SignalRConnectionContextProvider,
  SignalRConnectionProps,
} from './SignalrConnectionContextProvider';
import { useSelector } from 'react-redux';

export const SignalRProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [connectionProps, setConnectionProps] =
    useState<SignalRConnectionProps | null>(null);

  const [messageReceived, setMessageReceived] = useState(false);

  const user = useSelector(authSelectors.getUser);

  useEffect(() => {
    if (!user) {
      return;
    }

    const connection = new signalR.HubConnectionBuilder()
      .withUrl('/signalr/MessageHub')
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.None)
      .build();

    connection.start();
    const newConnection = {
      connection: connection,
      messageReceived: messageReceived,
      setMessageReceived: setMessageReceived,
    };

    setConnectionProps(newConnection);

    return () => {
      if (newConnection && 'connection' in newConnection) {
        newConnection?.connection?.stop();
      }
    };
  }, [messageReceived, user]);

  const connection =
    connectionProps && 'connection' in connectionProps
      ? connectionProps?.connection
      : null;

  useEffect(() => {
    if (connection) {
      connection.on('MessageReceived', () => {
        setMessageReceived(true);
      });

      return () =>
        connection.off('MessageReceived', () => {
          setMessageReceived(true);
        });
    }
  }, [connection, setMessageReceived]);

  return (
    <SignalRConnectionContextProvider value={connectionProps}>
      {children}
    </SignalRConnectionContextProvider>
  );
};
