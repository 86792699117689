import * as signalR from '@microsoft/signalr';
import { createStrictContext } from 'envoc-strict-context';
import { Dispatch, SetStateAction } from 'react';

export type SignalRConnectionProps = {
  connection: signalR.HubConnection | null;
  messageReceived: boolean | null;
  setMessageReceived: Dispatch<SetStateAction<boolean>>;
};

export const [SignalRConnectionContextProvider, useSignalRConnectionContext] =
  createStrictContext<SignalRConnectionProps | null>({
    errorMessage: 'Unable to get SignalR Connection Context',
    name: 'SignalRConnectionContext',
  });
